import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = () => (
  <Layout className="thankyoupage">
    <section className="thankyou">
      <SEO title="Thank You" />
      <h1>Thanks for your message</h1>
      <p>we'll be in touch soon.</p>
    </section>
  </Layout>
)

export default ThankYouPage
